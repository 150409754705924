import os from 'node:os';
import { cssBundleHref } from '@remix-run/css-bundle';
import { json, type LinksFunction } from '@remix-run/node';
import {
  Link,
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  type MetaFunction } from
'@remix-run/react';
import { AuthenticityTokenProvider } from 'remix-utils/csrf/react';
import { HoneypotProvider } from 'remix-utils/honeypot/react';
import faviconAssetUrl from './assets/favicon.jpg';
import { GeneralErrorBoundary } from './components/error-boundary.tsx';
import { KCDShop } from './kcdshop.tsx';
import fontStylestylesheetUrl from './styles/font.css';
import tailwindStylesheetUrl from './styles/tailwind.css';
import { csrf } from './utils/csrf.server.ts';
import { getEnv } from './utils/env.server.ts';
import { honeypot } from './utils/honeypot.server.ts';

export const links: LinksFunction = () => {
  return [
  { rel: 'icon', type: 'image/svg+xml', href: faviconAssetUrl },
  { rel: 'stylesheet', href: fontStylestylesheetUrl },
  { rel: 'stylesheet', href: tailwindStylesheetUrl },
  cssBundleHref ? { rel: 'stylesheet', href: cssBundleHref } : null].
  filter(Boolean);
};

export async function loader() {
  const honeyProps = honeypot.getInputProps();
  const [csrfToken, csrfCookieHeader] = await csrf.commitToken();
  return json(
    { username: os.userInfo().username, ENV: getEnv(), honeyProps, csrfToken },
    {
      headers: csrfCookieHeader ? { 'set-cookie': csrfCookieHeader } : {}
    }
  );
}

function Document({ children }: {children: React.ReactNode;}) {
  return (
    <html lang="en" className="h-full overflow-x-hidden">
			<head>
				<Meta />
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width,initial-scale=1" />
				<Links />
			</head>
			<body className="flex h-full flex-col justify-between bg-background text-foreground">
				{children}
				<ScrollRestoration />
				<Scripts />
				<KCDShop />
				<LiveReload />
			</body>
		</html>);

}

function App() {
  const data = useLoaderData<typeof loader>();
  return (
    <Document>

			<div className="flex-1">
				<Outlet />
			</div>

			<div className="container mx-auto flex justify-between">
			</div>
			<div className="h-5" />
			<script
        dangerouslySetInnerHTML={{
          __html: `window.ENV = ${JSON.stringify(data.ENV)}`
        }} />

		</Document>);

}

export default function AppWithProviders() {
  const data = useLoaderData<typeof loader>();
  return (
    <AuthenticityTokenProvider token={data.csrfToken}>
			<HoneypotProvider {...data.honeyProps}>
				<App />
			</HoneypotProvider>
		</AuthenticityTokenProvider>);

}

export const meta: MetaFunction = () => {
  return [
  { title: 'RACROTECH' },
  { name: 'description', content: `Find tech products` }];

};

export function ErrorBoundary() {
  return (
    <Document>
			<div className="flex-1">
				<GeneralErrorBoundary />
			</div>
		</Document>);

}